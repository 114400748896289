const AjustesInventarioEnum = {
    ajusteNegativo: [
        'DAÑOS/FALLOS',
        'ERROR DE REGISTRO',
        'EXPIRACION',
        'INVENTARIO FISICO',
        'PERDIDA',
        'PRESTAMO ENTREGADO',
        'ROBO',
        'TRASFERENCIA',
        'MOVIMIENTO INTERNO',
        'DEVOLUCION',
        'OTRO MOTIVO'
    ],
    ajustePositivo: [
        'BONIFICACION',
        'ERROR DE REGISTRO',
        'INVENTARIO FISICO',
        'PRESTAMO RECIBIDO',
        'TRANSFERENCIA',
        'DEVOLUCION',
        'OTRO MOTIVO'
    ],
  };

  export default AjustesInventarioEnum;